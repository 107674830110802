@import url('https://fonts.googleapis.com/css2?family=Freehand&display=swap');

.container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(#151343, var(--tertiary-color));
    background-position: 25% 0;
    background-size: cover;
    color: #fff;
    min-height: 100vh;
    padding: 20px;
    padding-top: 65px;
    padding-bottom: 10px;
    text-align: center;
    justify-content: center;
}

.titlemerch {
    margin-top: 50px;
    font-family: "Freehand", cursive;
    font-weight: 400;
    padding-bottom: 0px;
    font-size: 3.5rem;
    text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
    color: white;
    display: none;
}

.description {
    max-width: 1200px;
    margin-bottom: 40px;
    font-size: 1.1rem;
    color: #7D7E80;
    /* Glassmorphism Effect */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.containmerch {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #ff8888;
  padding: 25px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) ;
    gap: 80px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
}

.tshirts {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    flex-direction: column;
}

.buttons {
    padding-top: 25px;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap; 
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    &hover{
        color: white;
    }
  }
  
  .buttonorder {
    display: inline-block;
    padding: 0.5rem 1rem; 
    font-size: 1rem;
    font-weight: bold;
    color: var(--secondary-color); 
    border: 2px solid var(--secondary-color); 
    text-decoration: none; 
    cursor: pointer;
    transition: all 0.3s ease; 
    border-radius: 5px; 
    text-align: center;
    font-family: Inter;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .faicon{
      margin-right: 10px;
      font-size: 1.3rem;
    }
    .faicon1{
      margin-right: 8px;
      font-size: 1.5rem;
    }
}
.buttonorder:hover {
    color: white;
}
.tshirtPlaceholder  {
    height: 420px;
    width: 420px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 3px solid;
    border-radius: 50%;
}

.instructions {
    /* max-width: 800px; */
    text-align: left;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.instructions ul {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 1rem;
}

.instructions li {
    margin-bottom: 10px;
    justify-content:space-between ;
}

.instructions a {
    color: white;
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .containmerch {
        gap: 0;
    }
    .titlemerch{
        margin-top: 0px;
        padding-bottom: 25px;
        display: block;
    }
    .tshirtPlaceholder{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tshirtPlaceholder img {
        max-width: 50%;
        height: auto;
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .tshirts {
        flex-direction: column;
        align-items: center;
    }
    .titlemerch{
        padding-bottom: 0px;
    }
    .tshirtPlaceholder img {
        max-width: 350px;
        height: auto;
        margin-top: 50px;
    }

    .containmerch {
        gap: 0;
    }

    .description {
        font-size: 1rem;
        padding: 2vw;
    }

    .instructions {
        padding: 2vw;
    }
}

@media (max-width:500px) {
    .container1{
        margin: 5px 5px;
    }
    .tshirtPlaceholder img{
        width: 100%;
    }
    .instructions{
        padding: 4vw;
    }
    .titlemerch{
        padding-bottom: 0px;
    }
    .tshirtPlaceholder{
        width: 300px;
        height: 300px;
    }
}
