@import url('https://fonts.googleapis.com/css2?family=Freehand&family=Karantina:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freehand&display=swap');

.contactUsCard * {
    box-sizing: border-box;
    margin: 0px;
}

.mainHeadingcoreteam {
    display: block;
    text-align: center;
    color: var(--color1);
    font-family: 'Freehand', sans-serif;
    font-size: 5rem;
    z-index: 2;
    text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
    overflow-y: hidden;
}

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 100%;
    width: 100vw;
    overflow: auto; 
    /* padding-bottom: 40px; */
    height: auto;
    overflow: hidden;
    /* background: linear-gradient(#0E1830,#000000); */
    background: linear-gradient(#161444,#000000);
    /* background: linear-gradient(#151343, var(--tertiary-color)); */
    background-attachment: fixed; 
    background-size: cover;
}

.container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: var(--bg-overlay-color); */
    pointer-events: none;
    z-index: 1;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sideBar {
    box-sizing: border-box;
    margin-left: 3rem;
    margin-right: 0vw;
    z-index: 3;
    width: fit-content;
}

.contactUsCard {
    margin-top: 10vh;
    margin-left: 18vw;
    text-align: center;
    width: auto;
    z-index: 3;
    overflow: auto;
    padding-bottom: 2rem;
}

.Team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: center;
    width: 77vw;
    gap: 4rem;
}

.TeamHeading {
    font-size: 3.5rem;
    margin-top: 20px;
    font-family: 'Karantina', sans-serif;
    margin-bottom: 5vh;
    letter-spacing: 1.5px;
    color: var(white);
}

.gradientBackground {
    position: absolute;
    bottom: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: url("../assets/bg/CoreTeam_Gradient.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    width: 100vw;
    height: 15%;
}

@media only screen and (max-width: 1000px) {
    .sideBar {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .contactUsCard {
        margin-left: 0;
        padding: 0;
        overflow-x: hidden;
        padding-bottom: 2rem;
        margin: 0;
    }

    .mainHeadingcoreteam {
        display: block;
        font-size: 4rem;
        margin-top: 1.2em;
    }

    .TeamHeading {
        font-size: 2.7rem;
    }

    .Team {
        width: 100vw;
        padding: 1rem;
        box-sizing: border-box;
        gap: 2rem;
    }

    .Team:hover {
        gap: 3.5rem;
        transition: all 0.5s ease-in-out;
    }
}