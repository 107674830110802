.container {
    /* background-image: url("../assets/bg/longfinal_trimmed.png"); */
    /* background: linear-gradient(#101936,#000000); */
    /* background-size: 100% 100%; */
    height: auto;
    width: auto;
    overflow: hidden;
    background-position: top;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 2.5vh;
    padding-top: 10vh;
    z-index: 0;
    position: relative;
}

.gradientBackground {
    background-image: url("../assets/bg/CoreTeam_Gradient.png");
    background-size: 100vw auto;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: 0;
    width: 100%;
    height: auto;
    position: relative;
    opacity: 1;
    display: flex;
    min-height: 30vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .gradientBackground {
        background-image: none;
    }
}