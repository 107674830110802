@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap");

.container {
  position: relative;
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 1500px;
}

.carousel {
  width: 100vw;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-item {
  position: absolute;
  width: auto;
  height: 85vh; 
  min-width: 300px; 
  min-height: 300px;
  aspect-ratio: 1/1; 
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  transition: transform 1s ease-in-out, width 0.3s ease, height 0.3s ease;
  border-radius: 10px;
  border: 0.5px solid var(--secondary-color);
  cursor: pointer;
}

.carousel-item img {
  width: 100%; 
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid var(--secondary-color);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-sizing: border-box;
}

.carousel-item img:hover {
  transform: scale(1.05); 
}

.heading {
  font-family: "Freehand", cursive;
  font-family: "Karantina", system-ui;
  font-size: 3.5rem;
  text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
  color: white;
}

@media screen and (max-width: 720px) {
  .container {
    height: 60vh; 
  }

  .heading {
    font-size: 3.5rem;
  }

  .carousel-item {
    width: auto;
    height: 55vh; 
  }
}
