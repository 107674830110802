@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Pacifico&family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Fredoka:wght@300..700&family=Pacifico&family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Fredoka:wght@300..700&family=Lobster&family=Pacifico&family=Righteous&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap');

@font-face {
  font-family: 'Karantina';
  src: url('../assets/fonts/Karantina-Bold.ttf');
}


.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  height: 70vh;
  width: 50vw;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 3rem;
  color: white;
  animation: fade 1.5s ease-in-out;
  background: rgba(130, 130, 130, 0.2);
  box-shadow: #FFC370 0px 2px 6px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  font-size: 1.1rem;
  background: rgba( 0, 0, 0, 0.3 );
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  h1 {
    font-size: 2rem;
    color: #FFC370
  }

  img {
    height: auto;
    width: 50%;
  }

  button {
    font-family: "Pacifico", cursive;
    padding: 0;
    margin-top: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 2px solid #FFC370;
    transition: all 0.2s linear;
  }
  button:hover {
    color: #FFC370;
    transform: translateY(-2px);
  }
}

@media screen and (max-width: 520px) {
  .overlay {
    height: max-content;
    padding: 1rem 2rem;

    h1 {
      font-size: 1rem;
    }

    button {
      margin-top: 0.5rem;
      font-size: 0.7rem;
      padding: 0.5rem 0.5rem;
    }

    img {
      width: 80%;
    }

    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1024px) {
  .overlay {
    width: 80vw;
  }
}


.helper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10px;

  div:nth-child(1) {
    height: 1px;
    width: 50%;
    border: 1px solid #FFC370;
  }

  div:nth-child(2) {
    height: 1px;
    width: 1px;
    border: 1px solid #FFC370;
    border-radius: 50%;
  }

  div:nth-child(3) {
    height: 1px;
    width: 50%;
    border: 1px solid #FFC370;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.china {
  font-family: "Pacifico", cursive;
}

.europe {
  font-family: "Righteous", sans-serif;
}

.egypt {
  font-family: "Mooli", sans-serif;
  font-weight: 700;
}

.america {
  font-family: "Bungee", sans-serif;
}

.india {
  font-family: "Lobster", cursive;
}

.image {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 10vh;
  background-image: url("../assets/title.png");
}

.intro {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg {
  width: 100vw;
  height: 100vh;
  background: url("../assets/bg/bgfinal.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  filter: brightness(0.8);
}


.title {
  z-index: 10;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.typeface {
  width: 75%;
  height: auto;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  padding: 0px;
}

.prologue {
  background: rgba(130, 130, 130, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  text-align: center;
  background: rgba(50, 50, 50, 0.3);
  border-color: #FFC370;
  box-shadow: #FFC370 0px 2px 6px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
}

.heading {
  font-size: 2.5rem;
  font-weight: 300;
  font-family: "Karantina";
  color: #FFC370;
  z-index: inherit;
}

.body {
  font-size: 1.2rem;
  line-height: 1.1rem;
  color: white;
  z-index: inherit;
}

.go {
  font-size: 1.3rem;
  margin-top: 1rem;
  color: white;
  cursor: pointer;
  font-family: "Pacifico", cursive;
  outline: 0.01px solid #FFC370;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  padding: 0.25rem 1rem;
  border-radius: 20px;
  transition: all 0.2s linear;

  &:hover {
    transform: translateY(-2px);
  }
}

.hide {
  animation: hide 3s ease-in-out;
  display: none;
}


@keyframes hide {
  from {
    left: 0;
  }

  to {
    left: -9999px;
  }
}


@media screen and (max-width: 520px) {

  .prologue {
    width: 80vw;
    padding: 1rem 1rem;
  }

  .heading {
    font-size: 1.7rem;
  }

  .body {
    font-size: 0.9rem;
  }

  .go {
    font-size: 1.1rem;
  }
}