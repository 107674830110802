 /* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&display=swap');

 :root {
   --cardColor: #efc36a;
   --cardShadow: rgba(0, 0, 0, 0.25);
   --hoverShadow: rgba(0, 0, 0, 0.5);
   --primaryColor: #f5f0ed;
   --secondaryColor: #e94e77;
   --accentColor: #ff8888;
   --gradientColor: linear-gradient(135deg, rgb(2, 17, 39), #142C64);
   --bgGradient: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255,255,255,0.05));
 }
 
 .cardarea {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   position: relative;
   cursor: pointer;
   background: var(--bgGradient);
   border-radius: 20px;
   box-shadow: 0 10px 30px var(--cardShadow);
   height: 200px;
   width: 570px;
   width: clamp(200px, 40vw, 570px);  
   height: calc(0.38 * clamp(200px, 40vw, 570px));  
   padding: clamp(10px, 3vw, 20px);  
   transition: all 0.4s ease;
   margin: 20px;
   backdrop-filter: blur(15px);
   -webkit-backdrop-filter: blur(10px);
   border-style: solid;
   border-color: #ffffff;
 }
 
 .cardarea:before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: var(--gradientColor);
   opacity: 0;
   z-index: -1;
   transition: opacity 0.4s;
   border-radius: 20px;
 }
 
 .cardarea:hover:before {
   opacity: 1;
 }
 
 .cardarea:hover {
   transform: rotate(1deg) scale(1.05);
   box-shadow: 0 20px 40px var(--hoverShadow);
 }
 
 .content {
   height: 100%;
   width: 60%;
   color: white;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: clamp(8px, 2vw, 15px);  
 }
 
 .desc {
   font-size: clamp(0.5rem, 2.5vw, 1.5rem); 
   line-height: 1.6;  
   color: #ffffff;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
 }
 
 .button {
   margin-top: 15px;
   margin-bottom: 24px;
 }
 
 .button a {
   font-size: clamp(0.6rem, 2vw, 1.2rem);  
   color: #f5f0ed;
   padding: clamp(8px, 2vw, 12px) clamp(15px, 3vw, 20px); 
   background: var(--accentColor);
   border-radius: 50px;
   transition: background 0.3s ease, transform 0.3s ease;
   border: 2px solid transparent;
   text-decoration: none;
 }
 
 .button a:hover {
   background: var(--primaryColor);
   color: var(--secondaryColor);
   transform: translateY(-5px);
 }
 
 .bgImage {
   width: clamp(80px, 35%, 200px);
   height: clamp(60px, 25%, 150px);  
   background-size: cover;
   background-position: center;
   border-radius: 15px;
   border: 2px solid var(--primaryColor);
   filter: drop-shadow(0px 4px 10px var(--cardShadow));
   transition: transform 0.4s ease, filter 0.4s ease;
 }
 
 .cardarea:hover .bgImage {
   transform: scale(1.1);
   filter: drop-shadow(0px 8px 20px var(--hoverShadow));
 }
 
 
 @media (max-width: 1260px) {
   .cardarea {
     flex: 1 1 calc(50% - 40px);  
   }
 }
 
 @media (max-width: 250px) {
   .cardarea {
     flex: 1 1 calc(100% - 20px); 
     height: auto;
     flex-direction: column;
     width: 95%;
   }
 
   .bgImage {
     width: 100%;
     height: 200px;
     margin-bottom: 20px;
   }
 
   .content {
     width: 100%;
     text-align: center;
   }
 
   .desc {
     font-size: clamp(0.4rem, 4vw, 1rem);  
     line-height: 1.4;  
 
   .button a {
     font-size: clamp(0.6rem, 3vw, 1rem);  
     padding: clamp(8px, 3vw, 10px); 
   }
   .button {
   
    margin-top: clamp(15px);
    margin-bottom: clamp(24px);
  }
 }
} 

 */




 /* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&display=swap');

 :root {
    --cardColor: #efc36a;
    --cardShadow: rgba(0, 0, 0, 0.25);
    --hoverShadow: rgba(0, 0, 0, 0.5);
    --primaryColor: #f5f0ed;
    --secondaryColor: #e94e77;
    --accentColor: #ff8888;
    --gradientColor: linear-gradient(135deg, rgb(2, 17, 39), #142C64);
    --bgGradient: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255,255,255,0.05));
 }
 
 .cardarea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    background: var(--bgGradient);
    border-radius: 20px;
    box-shadow: 0 10px 30px var(--cardShadow);
    height: 200px;
    width: clamp(200px, 40vw, 570px);  
    height: calc(0.38 * clamp(200px, 40vw, 570px));  
    padding: clamp(10px, 3vw, 20px);  
    transition: all 0.4s ease;
    margin: 20px;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(10px);
    border-style: solid;
    border-color: #ffffff;
 }
 
 .cardarea:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gradientColor);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.4s;
    border-radius: 20px;
 }
 
 .cardarea:hover:before {
    opacity: 1;
 }
 
 .cardarea:hover {
    transform: rotate(1deg) scale(1.05);
    box-shadow: 0 20px 40px var(--hoverShadow);
 }
 
 .content {
    height: 100%;
    width: 60%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: clamp(8px, 2vw, 15px);  
    overflow: hidden; 
 }
 
 .desc {
    font-size: clamp(0.5rem, 2.5vw, 1.5rem); 
    line-height: 1.6;  
    color: #ffffff;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
 }
 
 .button {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start; 
 }
 
 .button a {
    font-size: clamp(0.4rem, 2vw, 1.2rem);  
    color: #f5f0ed;
    padding: clamp(8px, 1vw, 12px) clamp(15px, 3vw, 20px); 
    background: var(--accentColor);
    border-radius: 50px;
    transition: background 0.3s ease, transform 0.3s ease;
    border: 2px solid transparent;
    text-decoration: none;
    white-space: nowrap; 
    max-width: 100%; 
 }
 
 .button a:hover {
    background: var(--primaryColor);
    color: var(--secondaryColor);
    transform: translateY(-5px);
 }
 
 .bgImage {
    width: clamp(80px, 35%, 200px);
    height: clamp(60px, 25%, 150px);  
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    border: 2px solid var(--primaryColor);
    filter: drop-shadow(0px 4px 10px var(--cardShadow));
    transition: transform 0.4s ease, filter 0.4s ease;
 }
 
 .cardarea:hover .bgImage {
    transform: scale(1.1);
    filter: drop-shadow(0px 8px 20px var(--hoverShadow));
 }
 
 @media (max-width: 1260px) {
    .cardarea {
      flex: 1 1 calc(50% - 40px);  
    }
 }
 
 @media (max-width: 250px) {
    .cardarea {
      flex: 1 1 calc(100% - 20px); 
      height: auto;
      flex-direction: column;
      width: 95%;
    }
 
    .bgImage {
      width: 100%;
      height: 200px;
      margin-bottom: 20px;
    }
 
    .content {
      width: 100%;
      text-align: center;
    }
 
    .desc {
      font-size: clamp(0.4rem, 4vw, 1rem);  
      line-height: 1.4;  
    }
 
    .button a {
      font-size: clamp(0.5rem, 3vw, 1rem);  
      padding: clamp(8px, 2vw, 10px); 
    }
 
    .button {
      margin-top: 10px; 
      margin-bottom: 15px; 
    }
 }
  */

  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&display=swap');

:root {
  --cardShadow: rgba(0, 0, 0, 0.25);
  --hoverShadow: rgba(0, 0, 0, 0.5);
  --primaryColor: #f5f0ed;
  --secondaryColor: #e94e77;
  --accentColor: #ff8888;
  --gradientColor: linear-gradient(135deg, rgb(2, 17, 39), #142C64);
  --bgGradient: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255,255,255,0.05));
}

.cardarea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: var(--bgGradient);
  border-radius: 20px;
  box-shadow: 0 10px 30px var(--cardShadow);
  width: clamp(200px, 40vw, 570px);  
  height: calc(0.38 * clamp(200px, 40vw, 570px));  
  padding: clamp(10px, 3vw, 20px);  
  transition: all 0.4s ease;
  margin: 20px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(10px);
  border-style: solid;
  border-color: #ffffff;
}

.cardarea:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradientColor);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.4s;
  border-radius: 20px;
}

.cardarea:hover:before {
  opacity: 1;
}

.cardarea:hover {
  transform: rotate(1deg) scale(1.05);
  box-shadow: 0 20px 40px var(--hoverShadow);
}

.content {
  width: 60%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0;  
}

.desc {
  font-size: clamp(0.5rem, 2.5vw, 1.2rem); 
  line-height: 1.6;  
  color: #ffffff;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.button {
  margin-top: 2rem;
}

.button a {
  font-size: clamp(0.6rem, 2vw, 0.8rem);  
  color: #f5f0ed;
  padding: clamp(8px, 2vw, 12px) clamp(15px, 3vw, 20px); 
  background: var(--accentColor);
  border-radius: 50px;
  transition: background 0.3s ease, transform 0.3s ease;
  border: 2px solid transparent;
  text-decoration: none;
}

.button a:hover {
  background: var(--primaryColor);
  color: var(--secondaryColor);
  transform: translateY(-5px);
}

.bgImage {
  width: clamp(80px, 35%, 200px);
  height: 100%;  
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  border: 2px solid var(--primaryColor);
  filter: drop-shadow(0px 4px 10px var(--cardShadow));
  transition: transform 0.4s ease, filter 0.4s ease;
}

.cardarea:hover .bgImage {
  transform: scale(1.1);
  filter: drop-shadow(0px 8px 20px var(--hoverShadow));
}

/* Responsive Styles */

@media (max-width: 768px) {
  .cardarea {
    flex-direction: column;
    height: auto;
  }

  .content {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .bgImage {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
  }

  .desc {
    font-size: clamp(0.4rem, 4vw, 1rem);  
    line-height: 1.4;
  }

  .button a {
    font-size: clamp(0.6rem, 3vw, 1rem);  
    padding: clamp(8px, 3vw, 10px); 
  }
}

@media (max-width: 480px) {
  .cardarea {
    width: 100%;  
    padding: 10px;
  }

  .content {
    padding: 5px;
  }

  .bgImage {
    height: 180px;
  }
}

@media (max-width: 350px) {
  .cardarea {
    width: 95%;
    padding: 10px;
  }

  .bgImage {
    height: 150px;
  }
}
