@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&family=Roboto&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Mooli&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap");

@font-face {
    font-family: 'Karantina'; 
    src: url(../assets/fonts/Karantina-Regular.ttf);
}

.container {
    margin: 0;
    right: 0;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    /* padding-bottom: 65px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background-size: cover;
    /* background-repeat: no-repeat; */
    /* box-sizing: border-box; */
    /* background-attachment: fixed; */
}

.bigbox {
    /* background: linear-gradient(var(--start-backgroundbg), var(--tertiary-color)); */
    /* background-attachment: fixed; */
    height: 100%;
    min-height: 100vh;
    /* padding-bottom: 120px; */
    padding: 3%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: auto;
    /* background: linear-gradient(#0E1830,#000000); */
    background: linear-gradient(#161444,#000000);
}

.mainheading {
    display: block;
    text-align: center;
    color: var(--color1);
    font-family: 'Freehand', sans-serif;
    font-size: 5rem;
    z-index: 2;
    text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 0vh;
    }

    .bigbox {
        /* height: 100%; */
        padding-top: 45px;
        padding-bottom: 25px;
    }

    .mainheading {
        font-size: 3.0em;
        display: block;
        margin-top: 2.5rem;
    }
}