@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&family=Roboto&display=swap');
@font-face {
    font-family: 'zincboom';
    src: url('../assets/StarAttractions/zincboom.ttf');
}
@font-face {
    font-family: 'cloudy';
    src: url('../assets/StarAttractions/CloudyJune.ttf');
}

.card{
    display: grid;
    grid-template-columns: 420px ;
    grid-template-rows: 280px 130px 10px;
    grid-template-areas: "image" "text" "stats";

    font-family: 'Joseph', sans-serif;
    border-radius: 18px;
    color: white;
    /* box-shadow: 5px 5px 15px rgba(43, 18, 76, 0.9); */
    text-align: justify;
    transform-style: preserve-3d;
    perspective: 1000px;
    margin: 10px;
    margin-top: 40px;
    /* padding: 20px; */
    border: 2px solid var(--secondary-color);
}

.card-text{
    grid-area: text;
    margin: 27px;
    margin-top: 10px;
    transform: translateZ(20px);
    text-align: center;
}

.card-text h2{
    text-align: center;
    margin-top: 0px;
    padding-top: 5px;
    font-family: zincboom;
    font-size: 30px;
    /* font-weight: 900; */
    color: var(--secondary-color);
    font-family: 'Josefin Sans', sans-serif;
}
.card-text .description {
    color: white;
    font-size: 20px;
    font-weight: 300;
    font-family: cloudy;
    height: 80px;
    margin-top: 20px;
    overflow: hidden;
    display: -webkit-box; /* Required for -webkit-line-clamp to work */
    -webkit-box-orient: vertical; /* Required for -webkit-line-clamp to work */
    -webkit-line-clamp: 3; /* Display up to 3 lines of text before ellipsis */
    text-overflow: ellipsis;
}

.card-stats{
    grid-area:stats;
}
.js-tilt-glare{
    border-radius: 18px;
}
.veiw {
    .anchor{
        color: var(--secondary-color);
        text-decoration: none;
    }
    --btn-color: rgb(104, 55, 153);
    border: .1em solid var(--glow-color);
    padding: 0.8em 2.5em;
    font-size: 24px;
    font-weight: bold;
    font-family: cloudy;
    background-color: transparent;
    border-radius: 0.8em;
    position: relative;
    transition: all 0.3s; 
    /* margin-top: 15px; */
   }

   
   /* .veiw:hover {
    .anchor{
        color: var(--glow-spread-color);
    }
    background-color: var(--glow-color);
    box-shadow: 0 0 0.5em .2em var(--glow-color),
           0 0 2em 0.6em var(--glow-spread-color),
           inset 0 0 .75em .25em var(--glow-color);
   }
    */
   /* .veiw:active {
    box-shadow: 0 0 0.6em .25em var(--glow-color),
           0 0 2.5em 2em var(--glow-spread-color),
           inset 0 0 .5em .25em var(--glow-color);
   } */

   @media (max-width: 768px) {
    .card{
        grid-template-columns: 300px ;
        grid-template-rows: 160px 130px 10px;
        grid-template-areas: "image" "text" "stats";
    }
    .card-text h2{
        font-size: 25px;
    }
    .card-text .description {
        font-size: 15px;
    }
    .veiw {
        font-size: 20px;
    }
   }