.switch {
  position: fixed;
  font-size: 1.2rem;
  bottom: 1rem;
  left: 10px;
  z-index: 10000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.threed {
  font-family: "Mooli", cursive;
    left: auto;
    right: 1rem;
    top: 1rem;
    height: max-content;
    background-color: rgba(255, 255, 255, 0.3);
    /* text-transform: uppercase; */
    color: #005698;
    color: #FFC370;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;
    cursor: pointer;
    backdrop-filter: blur(5px);
    padding: 0.3rem 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
    overflow: hidden;
    transition: 0.3s ease;
    /* &:hover {
      color: #ffffff;
    }
    &::before {
      content: "";
      position: absolute;
      left: -50px;
      top: 0;
      width: 0;
      height: 100%;
      background-color: #005698;
      background-color: black;
      transform: skewX(45deg);
      z-index: -1;
      transition: width 1000ms;
    }
    &:hover::before {
      width: 200%;
    } */
     &:hover {
      transform: translateY(4px);
     }
}

@media screen and (max-width: 520px) {
  .threed {
    font-size: 0.7rem;
    padding: 0.2rem 1rem;
  }
}
