.outer_container{
    background: linear-gradient(#161444,#000000);
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .outer_container{
        background: linear-gradient(rgb(1,14,40),#000000);
        padding: 0;
        margin: 0;
    }
    
}