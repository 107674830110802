@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap");

.landinggallery {
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.heading {
  font-family: "Freehand", cursive;
  font-family: "Karantina", system-ui;
  font-size: 3.5rem;
  font-weight: 400;
  color: white;
  text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
  margin-bottom: 20px;
}

.bentoContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.bentoElement {
  position: relative;
  padding: 0;
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.imagess {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box;
}

.imagess:hover {
  transform: scale(1.1);
  transition: transform 0.25s;
}

@media (max-width: 576px) {
  .landinggallery {
    padding: 5vh 0;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .bentoContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .showgallery {
    padding: 0vh 0vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 10px;
  }

  .imagess:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
  }
}