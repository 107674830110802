.container {
  width: 55vw;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(80, 80, 80, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 35px;
  cursor: pointer;
}

.text {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 120%;
  margin: 0 2rem;
  padding: 2rem 0;
}

.text .year {
  color: #ffc4c4;
  font-size: 3rem;
  margin: 2rem 0;
}

.text .desc {
  color: white;
  font-size: 1.5rem;
}

.text::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -1rem;
  width: 90%;
  content: "";
  border-radius: 20px;
  border-bottom: 5px solid;
  border-image: linear-gradient(to right, #cf6b69, #69363500);
  border-image-slice: 1;
}

.imgContainer {
  position: relative;
  height: 125%;
  width: 40%;
  min-width: 400px;
  background-color: white;
  min-height: 105px;
}

.imgContainer .vert1,
.imgContainer .vert2 {
  position: absolute;
  height: 110%;
  width: 120%;
  border-top: 1px solid #cf6b69;
  border-bottom: 1px solid #cf6b69;
}

.imgContainer .vert1 {
  top: 49%;
  right: 50%;
  transform: translate(50%, -50%);
}

.imgContainer .vert2 {
  top: 51%;
  right: 49%;
  transform: translate(50%, -50%);
}

.imgContainer .hori1,
.imgContainer .hori2 {
  position: absolute;
  height: 120%;
  width: 110%;
  border-right: 1px solid #cf6b69;
  border-left: 1px solid #cf6b69;
}

.imgContainer .hori1 {
  top: 50%;
  right: 52%;
  transform: translate(50%, -50%);
}

.imgContainer .hori2 {
  top: 52%;
  right: 48%;
  transform: translate(50%, -50%);
}

@media screen and (max-width: 480px) {
  .container {
    width: 60vw;
    height: 70vh;
    flex-direction: column;
  }

  .text .year {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .text .desc {
    font-size: 0.8rem;
  }

  .text::after {
    display: none;
  }

  .imgContainer {
    width: 80%;
    margin: 1rem 0;
    min-height: 150px;
    height: 100%;
    min-width: 0;
  }

  .imgContainer .vert1,
  .imgContainer .vert2{
    height: 105%;
    width: 110%;
  }

  .imgContainer .hori1,
  .imgContainer .hori2 {
    height: 110%;
    width: 103%;
  }

  .imgContainer.hori1{
    right: 50.5%;
  }

  .imgContainer .hori2{
    right: 49.5%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 481px) {
  .container {
    width: 60vw;
    height: 60vh;
    flex-direction: column;
    max-width: 550px;
  }

  .text .year {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .text .desc {
    font-size: 1rem;
  }

  .text::after {
    display: none;
  }

  .imgContainer {
    width: 80%;
    margin: 1rem 0;
    min-width: 0;
  }

  .imgContainer .vert1,
  .imgContainer .vert2 {
    height: 105%;
    width: 110%;
  }

  .imgContainer .hori1,
  .imgContainer .hori2 {
    width: 105%;
    height: 110%;
  }

  .imgContainer .hori1{
    right: 51%;
  }

  .imgContainer .hori2{
    right: 49%;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1201px) {
  .container {
    width: 60vw;
    height: 35vh;
  }

  .text .year {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .text .desc {
    font-size: 1rem;
  }

  .text::after {
    display: none;
  }

  .imgContainer {
    height: 110%;
    width: 200%;
    min-width: 250px;
    margin: 1rem 0;
  }

  .imgContainer .vert1,
  .imgContainer .vert2 {
    height: 105%;
    width: 110%;
  }
  
  .imgContainer .hori1,
  .imgContainer .hori2{
    height: 110%;
    width: 105%;
  }

  .imgContainer .hori1{
      right: 50.75%;
  }

  .imgContainer .hori2{
    right: 49.25%;
  }

}
