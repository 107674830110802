@import url('https://fonts.googleapis.com/css2?family=Freehand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freehand&family=Karantina:wght@300;400;700&display=swap');

.appSchedule {
  background: linear-gradient(#151343, var(--tertiary-color));
  background-attachment: fixed;
  background-size: cover;
  padding: 2rem; 
}

.scheduleCard{
height: 25vh;
}

.scheduleHeading {
  display: block;
  text-align: center;
  color: var(--color1);
  font-family: 'Freehand', sans-serif;
  font-size: 5rem;
  z-index: 2;
  text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
  margin-top: 1.5rem;
  overflow: hidden;
}

.leftEmptySpace, .rightEmptySpace {
  width: 100%;
  height: 100%; 
  position: absolute; 
  top: 0;
}

.leftEmptySpace {
  left: 127%; 
}

.rightEmptySpace {
  right: 127%; 
}

.emptySpaceImage {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 8px; 
}

.scheduleDays {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 2vmin;
  top: 50px; 
  z-index: 10; 
  padding: 1rem;
  border-radius: 8px;
}

.scheduleDay {
  background-color: transparent;
  color: white;
  padding: 1rem;
  border: none;
  cursor: pointer;
  font-family: 'Mooli', cursive;
  font-size: 1rem;
  font-weight: 600;
}

.scheduleDay:hover {
  color: var(--secondary-color);
}

.activeDay {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.timelineElementContent {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(4.3px);
  color: var(--secondary-color);
  border-radius: 8px;
}

.timelineElementContent:hover {
  transform: scale(1.03);
  transition: 0.3s ease;
}

.scheduleTitle {
  font-size: 2.2rem; 
  font-weight: 500;
  font-family: 'Karantina', sans-serif;
  color: var(--primary-color);
  text-shadow: 0.5px 0.5px 1px rgb(124, 112, 112);
  margin-bottom: 0.75rem;
}

.scheduleVenue, .scheduleTime {
  font-size: 1.6rem; 
  font-weight: 400;
  font-family: 'Karantina', sans-serif;
  color: var(--primary-color);
  margin-bottom: 0.3rem; 
}

.scheduleMapbutton {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  margin: 0.3rem 0; 
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.scheduleMapButton:hover {
  background: var(--secondary-color);
  color: var(--primary-color);
}

.scheduleCard {
  background-color:var(--secondary-color);
}


@media (max-width: 1171px) {
  .leftEmptySpace, .rightEmptySpace{
    display: none;
  }
}

@media (max-width: 450px) {
  .scheduleHeading {
    font-size: 4rem;
    margin-top: 2.5rem;
  }
  .scheduleDays {
    flex-direction: row;
    align-items: center;
    /* gap: 0.5rem; */
  }

  .scheduleDay {
    font-size: 1.05rem;
    font-weight: 300;
  }
  .scheduleCard{
    height: 15vh;
    border-radius: 100px;
    padding-bottom: 8.5rem;
    }

    .scheduleTitle {
      font-size: 1.8rem; 
      font-weight: 500;
      font-family: 'Karantina', sans-serif;
      color: var(--primary-color);
      /* text-shadow: 2px 2px 4px black; */
      /* margin-bottom: 0.5rem; */
    }
    
    .scheduleVenue, .scheduleTime {
      font-size: 1.4rem; 
      font-weight: 400;
      font-family: 'Karantina', sans-serif;
      /* text-shadow: 2px 2px 4px black; */
      color: var(--primary-color);
      /* margin-bottom: 0.5rem; */
    }

    
}

@media (max-width: 1200px) {
  .scheduleDays {
    justify-content: space-between;
  }
}
