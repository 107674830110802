.container{
    width: 90vw;
    height: 35vh;
    display: grid;
    grid-template-rows: 4fr 1fr;
    grid-template-columns: 1fr;
}

.top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
    border-bottom: 2px solid var(--muted);
    .links{
        width: 100%;
        display: flex;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        .item{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            width: 100%;
            font-size: 0.85rem;
            .img{
                width: 80%;
            }
            .button{
                background-color: var(--button-bg);
                /* font-weight: bold;    */
                border-radius: 5px;
                /* margin-left: 1rem; */
                padding: 0.5rem 0.75rem;
                cursor: pointer;
                transition: 1s ease-in cubic-bezier(0.165, 0.84, 0.44, 1);
            }
            .button:hover{
                transform: scale3d(1.05, 1.05, 1.05);
                color: white;
            }
            .title{
                color: var(--muted)
            }
            .list{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                row-gap: 0.25rem;
                margin: 0.6rem 0;
                cursor: pointer;
                font-family: Inter;
                font-weight: 400;
                line-height: 30px;
                text-align: left;

                .listLink{
                    color: var(--color1);
                    text-decoration: none;
                    &::after{
                        display: block;
                        content: '';
                        border-bottom: 1px solid var(--muted);
                        transform: scaleX(0);
                        transition: transform 250ms ease-in-out;
                        transform-origin: 0 50%;
                    }
                    &:hover::after{
                        transform: scaleX(1);
                    }
                }
                
            }
        }

    }
    .socials{
        width: 100%;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2rem;
        color: var(--muted);
        .icons{
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 1rem;
            .faIcon{
                color: var(--muted);
                &:hover{
                    color: var(--secondary-color);

                }
            }
        }
    }

}

.bottom{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .item{
        color: var(--muted);
        text-decoration: none;
        text-align: center;
    }
}


@media screen and (max-width: 720px) {
    .container{
        width: 100vw;  
    }
    .socials{
        text-align: center;
        font-size: 0.75rem !important;
        .icons{
            /* display: none !important; */
            font-size: 1rem;
        }
    }

    .links > .item:nth-child(2), 
    .links > .item:nth-child(3), 
    .links > .item:nth-child(4){
        display: none;
    } 
    .top{
        padding: 0;
    }

    .bottom{
        justify-content: space-around;
    }
    .bottom > .item{
        font-size: 0.75rem;
    }
    /* .bottom > .item:nth-child(2), .bottom > .item:last-child{
        display: none !important;
    } */
}