@import url("https://fonts.googleapis.com/css2?family=Mooli&display=swap");

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10px;
  padding: 30px 10px;
  /* margin: 0 25px; */
  position: fixed;
  margin-top: 0;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 999;
  /* border-radius: 0% 0% 30px 30px; */
  /* border-left: 1px solid var(--secondary-color); 
    border-right: 1px solid var(--secondary-color);  */
    font-family: 'Mooli', cursive;
}

.rootPageLink:hover {
  color: var(--secondary-color);
}

.heading {
  font-family: "Mooli", sans-serif;
  font-size: 1.5rem;
  color: white;
  margin: 0;
  padding: 15px;
  letter-spacing: 2px;
  text-decoration: none;
}

.navbarLogo img {
  height: 40px;
  /* margin: 15px 0px; */
  /* margin-left: 25px; */
  /* width: 130px; */
}

.navbarLinks {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li a {
  color: initial;
  transition: color 0.3s;
}

li a:hover {
  color: var(--secondary-color);
}

.navbarLinks li {
  margin: 0 7.5px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.link,
.navbarLinks li a {
  font-size: 15px;
  position: relative;
}

.link::after,
.navbarLinks li a::after {
  content: "";
  width: 0%;
  height: 1.5px;
  background: var(--secondary-color);
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.25s;
}

.link:hover::after,
.navbarLinks li a:hover::after {
  width: 100%;
}

.link:hover {
  color: var(--secondary-color);
}

a:hover {
  color: var(--secondary-color);
}

.navbarLinks a {
  text-decoration: none;
  color: #fff;
}

.socialIcons {
  display: flex;
}

.faIcon {
  margin: 0 7px;
  font-size: 20px;
  color: #fff;
}

.faIcon:hover {
  color: var(--secondary-color);
}

.socialIcons a {
  height: 1.1rem;
}

.socialIcons a:hover {
  color: var(--secondary-color);
}

.activeLink {
  color: var(--secondary-color) !important;
  /* text-shadow: 0 0 5px #FFD700;  */
  position: relative;
}

a.activeLink::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 100%;
  height: 1.5px;
  background-color: var(--secondary-color);
  transition: width 0.3s;
}

a.activeLink:hover::after {
  width: 100%;
}

.mobileMenuButton {
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.drawerIcon {
  color: white;
  /* padding-right: 10px; */
}

.rotateIcon {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  color: var(--secondary-color);
}

@media (max-width: 1150px) {
  .mobileMenuButton {
    display: block;
  }

  .navbarLinks {
    display: none;
  }

  .socialIcons {
    display: none;
  }
}

.dropdown {
  position: relative;
  color: #fff;
  text-align: center;
}

.dropdown:hover {
  color: var(--secondary-color);
}

.dropdownMenu {
  display: none;
  position: absolute;
  top: 110%;
  left: -12%;
  list-style: none;
  background-color: #333;
  /* width: 200px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  margin: 0%;
  padding: 2px 5px;
  border-radius: 10px;
  text-align: center;
  /* padding-right: 15px; */
}

.dropdownMenu li{
  text-align: center;
}

.dropdownMenu li a {
  text-align: center;
  color: white;
  padding: 6px 0px;
  margin-bottom: 6px;
  font-size: 14px;
  text-decoration: none;
  display: block;
  transition: all 0.3s ease-in-out;
}

.dropdownMenu li a:hover {
  color: var(--secondary-color);
}

.dropdown:hover .dropdownMenu {
  display: block;
  list-style: none;
}

.dropdownItem {
  color: white;
  padding: 12px 16px;
  font-size: 10px;
  margin-bottom: 6px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdownMenu li a::after {
  content: none;
  width: 0%;
  height: 0px;
  background: var(--secondary-color);
  position: absolute;
  left: 0;
  bottom: 0px;
  transition: 0.25s;
}

.dropdownToggle {
  display: inline-flex;
  align-items: center; 
  cursor: pointer; 
  margin-left: 5px; 
}

.dropdownToggle svg {
  width: 12px; 
  height: 12px;
  margin-left: 2.5px;
  padding-top: 2.5px;
  fill: white;
  transition: transform 0.2s ease; 
}

.dropdownToggle:hover svg {
  transform: rotate(180deg); 
  fill: var(--secondary-color);
}
