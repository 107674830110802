.tempHome {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  width: 100vw;
  height: 190vh;
  background: url("../assets/bg/bgfinal.jpg");
  /* background-attachment: fixed; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  filter: brightness(0.8);
}

.title {
  z-index: 10;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.title>h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 10vw;
  margin-left: 30px;
  margin-right: 30px;
  letter-spacing: 5px;
  padding: 0px;
}

.title>h3 {
  font-size: 3vw;
  margin-bottom: 35px;
}

.typeface {
  width: 75%;
  height: auto;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  padding: 0px;
}

.threeD {
  font-family: 'Mooli', cursive;
  font-size: 1.3rem;
  background-color: rgba(255, 255, 255, 0.3);
  /* text-transform: uppercase; */
  color: var(--button-bg);
  position: absolute;
  bottom: -60%;
  right: 50%;
  transform: translateX(50%);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  backdrop-filter: blur(5px);
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;

  &:hover {
    color: #ffffff;
  }

  &::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: var(--button-bg);
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }

  &:hover::before {
    width: 200%;
  }
}

@media screen and (max-width: 480px) {
  .bg {
    background-image: url("../assets/bg/extended_landing_page_mobile_view.jpg");
    height: 100vh;
  }

  .typeface {
    transform: translateY(150px);
    height: auto;
    width: 100%;
  }

  .threeD {
    bottom: -180px;
    font-size: 1.1rem;
    padding: 0rem 0.5rem;
    color: #FFC370;
    background: rgba(0, 0, 0, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    /* border-width: 2px; */
  }
}