@import url('https://fonts.googleapis.com/css2?family=Island+Moments&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Island+Moments&family=Karantina:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Mooli&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap");

.about {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.left {
    width: 45%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: end;
    border-right: 5px solid white;
}

.heading {
    color: #FF8888;
    font-size: 3rem;
    font-family: "Karantina", system-ui;
    font-weight: 300;
    font-style: bold;
    letter-spacing: 3.5px;
    margin-bottom: 1rem;
    /* font-family: "Freehand", cursive;
    font-size: 3.5rem;
    color: white;
    text-shadow: 0px 4px 20px rgba(252, 155, 155, 1); */
}

.year {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 15px;
}

.year div {
    color: white;
    padding: 8px 15px;
    text-decoration: none;
    font-size: 18px;
    /* line-height: 30px; */
    border-radius: 5px;
    transition: transform 0.3s ease;
    position: relative;
    cursor: pointer;
}

.year div {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
    border: 1px solid white;
}


.year div:hover {
    transform: translateY(-2px);
}

.year div.active {
    border: 1px solid #FF8888;
    color: white;
    transform: translateY(-5px);
    box-shadow: 0px 0px 10px rgba(256, 133, 133, 0.9);
}

.video {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 10px;
    height: auto;
    aspect-ratio: 16/9;
}

.bottom {
    font-size: 28px;
    color: white;
    font-family: "Island Moments", cursive;
    font-weight: 400;
    font-style: normal;
    padding: 10px 25px 0px;
    margin-bottom: 0;
    border-bottom: 2px solid #383838;
    cursor: pointer;
}

.right {
    width: 45%;
    padding: 2rem;
}

.para {
    /* color: white;
    font-size: 1.2rem;
    font-family: "Inter", sans-serif; */
    font-optical-sizing: auto;
    font-weight: 400;
    /* font-style: italic; */
    line-height: 30px;
    /* text-align: justify;  */
    font-family: "Mooli", sans-serif;
    font-size: 1.2rem;
    /* margin: 5px; */
    margin-bottom: 30px;
    color: white;

}

.rotatingnumber {
    display: flex;
    flex-direction: row;
}

.bottomfield {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cont {
    color: white;
    display: flex;
    flex-direction: column;
    line-height: 5px;
    width: max-content;
    padding: 15px;
    justify-content: center;
    align-items: center;
}

.cont p {
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 1;  
    margin: 0;
}

@media screen and (max-width: 480px) {
    .about {
        display: flex;
        flex-direction: column-reverse;
        padding: 40px;
    }

    .right {
        padding-right: 0;
        padding-left: 0%;
        text-align: center;
        width: 100%;
        border-bottom: 2px solid var(--muted);
    }

    .heading {
        text-align: center;
        font-size: 50px;
    }

    .para {
        font-size: 1.2rem;
        text-align: center;
    }

    .cont {
        padding: 8px;

    }

    .cont p {
        font-size: 1.1rem;
    }

    .bottom {
        display: none;
    }

    .left {
        align-items: center;
        padding-right: 0;
        border: none;
        padding-left: 0;
        width: 100%;
    }

    .year {
        width: 100%;
        gap: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .year a {
        padding: 0;
        font-size: 3.5vw;
        width: max-content;
    }

    .year div {
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
        border: 1px solid white;
        font-size: 0.8rem;
    }


    .year div:hover {
        transform: translateY(2px);
    }

    .year div.active {
        border: 1px solid #FF8888;
        color: white;
        transform: translateY(5px);
        box-shadow: 0px 0px 10px rgba(256, 133, 133, 0.9);
    }

    .video {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
    }
}

@media screen and (min-width:480px) and (max-width:1366px) {
    .about {
        display: flex;
        flex-direction: column-reverse;
        padding: 40px;
    }

    .right {
        padding-right: 0;
        padding-left: 0%;
        text-align: center;
        width: 100%;
        border-bottom: 2px solid var(--muted);
    }

    .heading {
        text-align: center;
        font-size: 50px;
    }

    .para {
        font-size: 1.22rem;
        text-align: center;
    }

    .cont {
        padding: 5px 10px;
    }

    .cont p {
        font-size: 2rem;
    }

    .bottom {
        display: none;
    }

    .left {
        align-items: center;
        padding-right: 0;
        border: none;
        padding-left: 0;
        width: 100%;
    }

    .year {
        width: 100%;
        gap: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .year a {
        padding: 0;
        font-size: 3.5vw;
        width: max-content;
    }

    .year div {
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
        border: 1px solid white;
    }


    .year div:hover {
        transform: translateY(-2px);
    }

    .year div.active {
        border: 1px solid #FF8888;
        color: white;
        transform: translateY(-5px);
        box-shadow: 0px 0px 10px rgba(256, 133, 133, 0.9);
    }

    .video {
        width: 80%;
        height: 30vh;
    }
}