.sponsors__imageDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 25%;
    min-width: 200px;
    margin: 10px 35px;
    box-sizing: border-box;
  }
  .sponsors__imageDiv a {
    text-decoration: none;
    color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sponsors__img {
    /* height: 200px; */
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    transition: 0.3s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    margin-bottom: 20px;
    padding: 20px;
    backdrop-filter: blur(15px);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .sponsors__img:hover {
    transform: scale(1.1);
  }
  
  .sponsors__desc {
    margin: 25px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
  
  @media (max-width: 600px) {
    .sponsors__imageDiv {
      width: 100%;
      margin: 0px 0px;
    }
    .sponsors__desc {
      font-size: 15px;
    }
    .sponsors__img {
      height: auto;
      width: 65%;
    }
  }