.organizersSection {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    box-sizing: border-box;
}

.container{
    width: 100vw;
    overflow: auto;
    height: 100vh;
    /* background: linear-gradient(#0E1830,#000000); */
    background: linear-gradient(#161444,#000000);
}

.organizersSection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.mainHeading {
    font-family: 'Freehand', sans-serif;
    font-size: 4rem;
    z-index: 2;
    text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
    margin-top: 6rem;
    display: block;
    text-align: center;
    font-weight: 400;
    padding-bottom: 1rem;
}

@media (max-width: 768px) {
    .mainHeading {
        font-size: 3rem;
    }
}

@media (max-width: 480px) {
    .mainHeading {
        font-size: 2.8rem;
    }
}

.organizerCard {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .organizerCard {
        flex: auto;
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .organizerCard {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: auto;
    height: 100vh;
}

.container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: var(--bg-overlay-color); */
    pointer-events: none;
    z-index: 1;
}

.container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: -1;
    pointer-events: none;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
