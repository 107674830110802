@import url("https://fonts.googleapis.com/css2?family=Mooli&display=swap");
.card {
    height: auto;
    width: 35vw;
    max-width: 400px; 
    background-color: #342E39;
    border-radius: 2%;
    border: 1px solid rgb(243, 200, 149);
    box-shadow: 0px 8px 15px rgba(215, 210, 210, 0.2);
    margin: 5%;
    display: flex;
    flex-direction: column; 
    align-items: center;
}

.cardImage {
    width: 125px; 
    height: 125px;
    /* max-width: 100px; */
    /* max-height: 100px; */
    position: relative;
    left:23%;
    margin-top: -16%;
    margin-bottom: -10%;
}

.cardImage:hover{
    cursor: pointer;
}

.cardImage img {
    width: 100%; 
    height: 100%; 
    border-radius: 50%; 
    box-shadow: 0px 8px 15px rgba(215, 210, 210, 0.2);
    border: 1.5px solid rgb(243, 200, 149);
}

.outer {
    display: flex;
    flex-direction: column;
    /* align-items: center;  */
    text-align: center; 
    margin: 0 1rem; 
}

.mark {
    margin: 1rem 0;
    position: relative;
    /* top:-10vh; */
    left: 1vw;
    width:3vw;
    margin: 2vh;
}

.quote {
    font-style: italic;
    font-family: "Mooli", sans-serif;
    font-size: 1.2rem; 
    padding: 0 1rem;
    line-height: 1.4;
}

.prize {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 1.5rem; 
}

.tr {
    width: 15%; 
    min-width: 50px;
    margin-right: 0.5rem; 
}

.specifics {
    width: 85%;
    text-align: center;
    font-size: 1.2rem; 
    font-family: 'Times New Roman', Times, serif;
    color: var(--secondary-color);
}

.college {
    font-size: 1.5rem; 
    text-align: center;
    margin: 0.5rem 0 2rem 0;
    position: relative;
}

.college::after {
    content: attr(data-text);
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) scaleY(-1);
    color: rgba(255, 255, 255, 0.4);
    opacity: 0.5;
    filter: blur(1px);
    /* background: linear-gradient(to top, rgba(18, 18, 18, 0) 25%, rgb(22, 22, 22, 0.2) 100%); */
    width: 100%;
    text-align: center;
}

.modal {
    display: flex; 
    position: fixed; 
    z-index: 1000; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba(0, 0, 0, 0.8); 
    justify-content: center;
    align-items: center;
}

.modalContent {
    max-width: 90%; 
    max-height: 90%; 
    margin: auto; 
}

.close {
    position: absolute; 
    top: 20px; 
    right: 30px; 
    color: #fff; 
    font-size: 40px; 
    font-weight: bold; 
    cursor: pointer; 
}

@media only screen and (max-width: 550px) {
    .card {
        width: 80vw;
        margin: 5vh 0;
    }
    .cardImage {
        width: 25vw; 
        height: 25vw; 
    }
    .outer {
        margin: 0 0.5rem; 
    }
    .mark{
        width: 10vw;
    }
    .quote {
        font-size: 1rem; 
    }
    .prize {
        flex-direction: row; 
    }
    .tr{
        max-width: 15vw;
    }
    .specifics {
        font-size: 1rem; /* Adjust font size */
    }
}

@media screen and (min-width: 550px) and (max-width: 850px) {
    .card {
        width: 80vw;
        margin: 5vh 0;
        margin-top: 5em;
    }
    .cardImage {
        width: 20vw;
        height: 20vw; 
        margin-top: -18%;
        left: 20%;
    }
    .mark{
        width: 10vw;
    }
    .quote {
        font-size: 1.5rem; 
    }
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
    .card {
        width: 30vw;
    }
    .cardImage {
        width: 13vw;
        height: 13vw; 
        margin-top: -22%;
        left: 20%;
    }
}
