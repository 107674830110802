.mobileDrawer {
  position: fixed;
  top: 4.5rem;
  right: -100%;
  padding: 20px 25px;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: right 0.3s;
  z-index: 9999999;
  border-radius: 30px 0px 0px 30px;
}

.mobileDrawer.open {
  right: 0;
}

.drawerLinks {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drawerLinks li {
  margin: 6px 0;
}

.drawerLinks a {
  color: var(--secondary-color);
  text-decoration: none;
  font-size: 24px;
}

.drawerLinks a:hover {
  color: white;
}

.dropdown {
  position: relative;
  color: var(--secondary-color);
  font-size: 25px;
}

.dropdown:hover {
  color: white;
}

.dropdownMenu {
  display: none;
  position: absolute;
  left: -155%;
  top: -200%;
  list-style: none;
  background-color: #333;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  margin: 0%;
  padding: 5px 20px;
  border-radius: 15px;
}

.dropdownMenu li a {
  text-align: center;
  color: white;
  padding: 6px 0px;
  margin-bottom: 6px;
  font-size: 12px;
  text-decoration: none;
  display: block;
  transition: all 0.3s ease-in-out;
}

.dropdownMenu li a:hover {
  color: var(--secondary-color);
}

.dropdown:hover .dropdownMenu {
  display: block;
  list-style: none;
}

.dropdownItem {
  color: white;
  padding: 12px 16px;
  font-size: 10px;
  margin-bottom: 6px;
  text-decoration: none;
  display: block;
}
