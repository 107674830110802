@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap");

.brochurepage {
  /* background: linear-gradient(#163272,#000000); */
  background: linear-gradient(#161444,#000000);
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* padding-top: 80px; */
  
  overflow: hidden;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brochurebox {
  /* width: 80%; */
  width: 90vh;
  margin: auto;
  margin-top: 70px;
}

.parahead {
  color: #fff;
  /* color: var(--secondary-color); */
  font-size: medium;
  padding: 0;
  margin: 0;
}

.nav_btn_right {
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  background: transparent;
  z-index: 100;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  /* background: var(--secondary-color); */
  background: #f50306;
  right: 0;
  cursor: pointer;
}

.nav_btn_left {
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  background: transparent;
  z-index: 100;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  /* background: var(--secondary-color); */
  background: #f50306;
  cursor: pointer;
}

.control-dots {
  display: none;
  visibility: hidden;
}

.nav_btn_right>svg {
  fill: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.nav_btn_left>svg {
  fill: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.slide {
  padding-top: 2rem;
}

.carousel .control-dots {
  z-index: 0;
}

.ind {
  width: 20px;
  height: 20px;
  list-style: none;
  border: 4px solid #f5f5f5;
  transform: rotate(135deg);
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 100;
}

.ind.active {
  background-color: #000;
  transition: 500ms 200ms;
}

.ind:not(:last-child) {
  margin-right: 1.4rem;
}

.carousel .carousel-status {
  left: 0;
  right: 0;
  text-align: center;
  color: #000;
  font-size: 1.4rem;
  text-shadow: none;
  margin-top: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.brochurebox ul {
  left: 0;
  bottom: 0;
}

.brochurebox li{
  transform: none;
}

.brochurehead{
display: none;
}

@media only screen and (max-width: 768px) {
  .brochurepage {
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .brochurebox {
    width: 100%;
  }
  .nav_btn_left, .nav_btn_right{
    height: 2rem;
    width: 2rem;
  }
  .brochurehead{
    display: block;
    margin-top: 4.5rem;
    font-family: "Freehand", cursive;
    font-size: 3.0rem;
    text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
    color: white;
    margin-bottom: -3rem;
  }
}