@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Mooli&display=swap");

.contents {
    background-color: var(--primary-color);
    /* background-color: #2B124C; */
    position: fixed;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Mooli', cursive;
  }
  
  .loaderdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loaderdiv div {
    margin-top: 15px;
    font-size: 2rem;
    color: var(--secondary-color);
    font-weight: 700;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid var(--secondary-color); 
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    z-index: 1000;
  }

  .string{
    margin-top: 30px;
    color: var(--secondary-color);
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0 25px;
  }

  .string p{
    margin-bottom: 60px;
    color: rgba(255, 255, 255, 0.3);
  }

  @media screen and (max-width: 768px) {
    .string{
      font-size: 1.5rem !important;
      display: block;
      padding: 15px;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.loader {
  --ANIMATION-DELAY-MULTIPLIER: 70ms;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 25px;
}
.loader span {
  padding: 0;
  margin: 0;
  letter-spacing: -5rem;
  animation-delay: 0s;
  transform: translateY(4rem);
  animation: hideAndSeek 1s alternate infinite cubic-bezier(0.86, 0, 0.07, 1);
}
.loader .l {
  animation-delay: calc(var(--ANIMATION-DELAY-MULTIPLIER) * 0);
}
.loader .o {
  animation-delay: calc(var(--ANIMATION-DELAY-MULTIPLIER) * 1);
}
.loader .a {
  animation-delay: calc(var(--ANIMATION-DELAY-MULTIPLIER) * 2);
}
.loader .d {
  animation-delay: calc(var(--ANIMATION-DELAY-MULTIPLIER) * 3);
}
.loader .ispan {
  animation-delay: calc(var(--ANIMATION-DELAY-MULTIPLIER) * 4);
}
.loader .n {
  animation-delay: calc(var(--ANIMATION-DELAY-MULTIPLIER) * 5);
}
.loader .g {
  animation-delay: calc(var(--ANIMATION-DELAY-MULTIPLIER) * 6);
}
.letter {
  width: fit-content;
  height: 3rem;
}
.i {
  margin-inline: 5px;
}
@keyframes hideAndSeek {
  0% {
    transform: translateY(4rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
