@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap");

.container {
  width: 75vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.heading {
  font-family: "Freehand", cursive;
  font-size: 2rem;
  color: white;
  text-shadow: 0px 4px 20px rgba(252, 155, 155, 1); 
  z-index: 10;
}

.title{
    font-size: 3.5rem;
    color: white;
    font-family: Karantina;
    font-weight: 400;
    line-height: 45.34px;
    letter-spacing: 3.7783734798431396px;
    text-align: center;
    z-index: 10;
}

.content{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 100%;
    background-color: black;
    color: white;
    margin: 3rem;
    position: relative;
    z-index: 10;
    .vert1{
        position: absolute;
        top: 49%;
        transform: translate(50%, -50%);
        right: 50%;
        height: 110%;
        width: 105%;
        border-top: 1px solid #CF6B69;
        border-bottom: 1px solid #CF6B69;
      }
      .vert2{
        position: absolute;
        top: 51%;
        transform: translate(50%, -50%);
        right: 49.5%;
        height:  110%;
        width: 105%;
        border-top: 1px solid #CF6B69;
        border-bottom: 1px solid #CF6B69;
      }
      .hori1{
        position: absolute;
        top: 50%;
        transform: translate(50%, -50%);
        right: 50.25%;
        height: 120%;
        width: 102%;
        border-right: 1px solid #CF6B69;
        border-left: 1px solid #CF6B69;
      }
      .hori2{
        position: absolute;
        top: 52%;
        transform: translate(50%, -50%);
        right: 49.75%;
        height: 120%;
        width: 102%;
        border-right: 1px solid #CF6B69;
        border-left: 1px solid #CF6B69;
      }
}

.text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    /* font-style: italic; */
    width: 100%;
    height: 80%;
    margin: 1rem;
    z-index: 10;
    padding: 0 1rem;
    padding-bottom: 1rem;
}

.subtext{
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 10;
}

.buttons {
  padding-top: 25px;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap; 
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}

.button {
  display: inline-block;
  padding: 0.5rem 1rem; /* Adjusts padding for a consistent button size */
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;
  color: var(--secondary-color); 
  border: 2px solid var(--secondary-color); 
  text-decoration: none; 
  cursor: pointer;
  transition: all 0.3s ease; 
  border-radius: 5px; 
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .faicon{
    margin-right: 10px;
    font-size: 1.3rem;
  }
  .faicon1{
    margin-right: 8px;
    font-size: 1.5rem;
  }
}

.button:hover {
  /* background-color: var(--secondary-color); */
  color: white; 
  /* transform: scale3d(1.05, 1.05, 1.05);  */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
  z-index: 10;
}


.image{
    width: 100%;
    height: 100%;
    background-image: url("../assets/JoinCommunity.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset rgba(0,0,0, 1) 10px 10px 40px;
    z-index: 10;
}

@media screen and (max-width: 720px) {
  .container{
    width: 90vw;
    height: 80vh;
    margin-bottom: 0;
  }
  .title{
    font-size: 2.5rem;
    text-align: center;
  }
  .text{
    row-gap: 0.8rem;
  }
  .heading{
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
  }

  .subtext{
    text-align: center;
    font-size: 0.8rem;
  }

  .buttons{
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }

  .button{
    font-size: 0.8rem;
    border: 2px solid var(--secondary-color);
    text-align: center;
  }

  .content{
    flex-direction: column;

    .vert1 {
      position: absolute;
      top: 49%;
      transform: translate(50%, -50%);
      right: 50%;
      height: 105%;
      width: 110%;
      border-top: 1px solid #cf6b69;
      border-bottom: 1px solid #cf6b69;
    }
    .vert2 {
      position: absolute;
      top: 51%;
      transform: translate(50%, -50%);
      right: 49%;
      height: 105%;
      width: 110%;
      border-top: 1px solid #cf6b69;
      border-bottom: 1px solid #cf6b69;
    }
    .hori1 {
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
      right: 51%;
      height: 112%;
      width: 105%;
      border-right: 1px solid #cf6b69;
      border-left: 1px solid #cf6b69;
    }
    .hori2 {
      position: absolute;
      top: 52%;
      transform: translate(50%, -50%);
      right: 49%;
      height: 112%;
      width: 105%;
      border-right: 1px solid #cf6b69;
      border-left: 1px solid #cf6b69;
    }
  }

  .image{
    background-size: cover;
  }
}

@media screen and (max-width: 1366px) and (min-width: 721px){
  .container{
    width: 90vw;
  }
  .title{
    font-size: 2.5rem;
    text-align: center;
  }
  .text{
    row-gap: 0.8rem;
  }
  .heading{
    width: 100%;
    font-size: 1.75rem;
    text-align: center;
  }

  .subtext{
    text-align: center;
    font-size: 1rem;
  }

  .buttons{
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }

  .button{
    font-size: 0.8rem;
    border: 2px solid var(--secondary-color);
    text-align: center;
  }

  .content{

    .vert1 {
      position: absolute;
      top: 49%;
      transform: translate(50%, -50%);
      right: 50%;
      height: 105%;
      width: 105%;
      border-top: 1px solid #cf6b69;
      border-bottom: 1px solid #cf6b69;
    }
    .vert2 {
      position: absolute;
      top: 51%;
      transform: translate(50%, -50%);
      right: 49%;
      height: 105%;
      width: 105%;
      border-top: 1px solid #cf6b69;
      border-bottom: 1px solid #cf6b69;
    }
    .hori1 {
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
      right: 50.5%;
      height: 112%;
      width: 102%;
      border-right: 1px solid #cf6b69;
      border-left: 1px solid #cf6b69;
    }
    .hori2 {
      position: absolute;
      top: 52%;
      transform: translate(50%, -50%);
      right: 49.5%;
      height: 112%;
      width: 102%;
      border-right: 1px solid #cf6b69;
      border-left: 1px solid #cf6b69;
    }
  }

  .image{
    background-size: cover;
  }
}
