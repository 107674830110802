.card {
  width: 14rem;
  min-width: 11rem;
  height: 25rem;
  /* From https://css.glass */
  background: #D3D3D3;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 5px solid;
  border-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5vw;
}

.cardPhoto {
  margin-bottom: 15px;
  width: 150px;
  min-width: 100px;
  height: 150px;
  border-radius: 30%;
}

.cardPhoto img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  border: 2px solid var(--color2);
}

.cardTitle {
  text-align: center;
  color: var(--color2);
  font-size: 1.5rem;
  font-weight: 650;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.para {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color2);
}

.cardSocials {
  display: flex;
  height: 8vh;
  opacity: 1;
  margin-top: 30px;
  gap: 15px;
  transition: 0.5s;
  align-items: center;
}

.cardSocialsBtn a {
  color: var(--color2);
  text-decoration: none;
}

.cardSocialsBtn:hover {
  transform: translateY(-5px);
  transition: all 0.15s;
}

.cardSocialsPara {
  color: var(--color2);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 100%;
}

.cardSocials button {
  background-color: transparent;
}