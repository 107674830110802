@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mooli&display=swap");

.gallery {
  margin: 0px;
  padding: 0;
  padding-top: 65px;
  box-sizing: border-box;
  /* background: linear-gradient(to right, #434343 0%, lightblue 100%); */
  /* background: linear-gradient(#2b124c, #190019); */
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Mooli", cursive;
  background-attachment: fixed;
  overflow: hidden;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  /* border: 2px solid #dcca87; */
  background: linear-gradient(#151343, var(--tertiary-color));
  background-attachment: fixed;
  background-size: cover;
}

.galleryTitle {
  display: block;
  text-align: center;
  color: var(--color1);
  font-family: 'Freehand', sans-serif;
  font-size: 5rem;
  z-index: 2;
  text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
  margin-top: 1.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  margin: 10px auto;
}

.galleryWrap .single {
  /* width: 320px; */
  cursor: pointer;
}

.single img {
  border-radius: 5px;
  /* border: 1px solid rgba(0, 0, 0, 1); */
  transition: all 0.15s ease-in-out;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 5px;
  height: 260px;
  width: auto;
  /* aspect-ratio: 16/9; */
}

.galleryWrap .single img {
  max-width: 100%;
}

.galleryWrap .single img:hover {
  transform: scale(1.08);
  /* box-shadow: 0px 0px 20px 1px #dcca87; */
  /* border: 1px solid rgba(0, 0, 0, 1); */
  overflow-x: hidden;
  overflow-y: hidden;
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
}

.sliderWrap .likeContainer {
  position: fixed;
  cursor: pointer;
  color: #fff;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.sliderWrap .likeContainer {
  top: 650px;
  right: 40px;
}

.fullScreenImage {
  /* width: calc(100% - 40px);  */
  height: calc(100% - 40px);
  padding: 0px 40px;
  aspect-ratio: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 25px;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.likeIcon {
  color: transparent;
  /* border: 1px solid red; */
  color: pink;
  opacity: 0.7;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s, border-color 0.2s;
  animation: 2s like-heart-animation ease-in-out forwards;
}

.likeIcon:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.likeIcon.active {
  color: #fb3958;
  border-color: #fb3958;
}

.likeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.likeCount {
  margin-top: 3.5px;
  text-align: center;
  font-size: 12px;
  color: aquamarine;
}

@media (max-width: 576px) {
  .galleryWrap {
    justify-content: center;
  }

  .galleryWrap .single {
    width: 100%;
    max-width: 450px;
    margin: 10px;
  }

  .single img {
    height: auto;
    width: 100%;
  }

  .galleryTitle {
    font-size: 3.5rem;
  }

  .sliderWrap .btnClose {
    top: 150px;
    right: 30px;
  }

  .sliderWrap .likeContainer {
    top: 600px;
    right: 30px;
  }

  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
  }

  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }

  .fullScreenImage {
    width: 100%;
    height: auto;
    padding: 0px 20px;
  }

  .galleryTitle {
    display: block;
  }
}

@media (max-width: 468px) {
  .galleryWrap {
    justify-content: center;
  }

  .galleryWrap .single {
    width: 100%;
    max-width: 320px;
    margin: 10px 15px;
  }

  .single img {
    height: auto;
    width: 100%;
    margin: 0;
  }

  .galleryTitle {
    font-size: 3.5rem;
  }

  .sliderWrap .btnClose {
    top: 175px;
    right: 20px;
  }

  .sliderWrap .likeContainer {
    top: 500px;
    right: 20px;
  }

  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }

  .fullScreenImage {
    width: 100%;
    height: auto;
    padding: 0px 15px;
  }
}