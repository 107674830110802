@import url('https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap');

@font-face {
  font-family: 'Karantina';
  src: url('../assets/fonts/Karantina-Bold.ttf');
}

.container {
  margin-top: 150px;
  width: 70vw;
  /* background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(10px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 1rem;
  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #FFC370;
  transition: opacity ease-in-out 0.1s;
  background: rgba(14, 24, 48, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 15px;
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

.text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform: translateY(1vh);
}

.inner {
  width: 100%;
  align-items: center;
  justify-content: center;
  /* font-size: 1.3vw; */
  /* height: 10vh; */
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

/* .hr1,
.hr2,
.hr3,
.hr4 {
  height: 2.5px;
  width: 40%;
  background-color: #FFC370;
  border: #FFC370;
  border-radius: 15px;
  border: 0cap;
  position: absolute;
  bottom: 0.8svh;
} */

/* .hr1 {
  top: 0;
}

.hr2 {
  top: 8%;
}

.hr3 {
  bottom: 0;
}

.hr4 {
  bottom: 8%;
} */

.events {
  font-family: 'Karantina';
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFC370;
  font-size: max(2.5vw, 2rem);
  font-weight: 400;
  letter-spacing: 2px;
}

.themehead{
  font-size: 1.5rem;
}

.theme {
  color: white;
  font-size: 1.25rem;
  text-align: center;
  /* margin: 0 1rem; */
  font-weight: 350;
  margin-bottom: 2rem;
  letter-spacing: -1px;
  padding: 0 4%;
  margin-top: 0px;
}

@media screen and (max-width: 720px) {
  .container {
    height: 80%;
    width: 90vw;
    margin-top: -40px;
  }

  .heading {
    margin: 0;
    font-size: 2rem;
  }

  .theme {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  .themehead{
    font-size: 1.5rem;
    /* margin-bottom: 15px; */
  }
  .themeheadant{
    display: block;
  }
}