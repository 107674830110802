.outer {
    min-height: 100vh; 
    height: auto;
    padding: 2%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    background: linear-gradient(#151343, var(--tertiary-color));
    background-attachment: fixed; 
    background-size: cover; 
}

.top {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;    
    z-index: 2;
}

.grad {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    z-index: 0;
}

.grad img {
    width: 80%;
    height: auto;
}

.h {
    font-size: 1.5rem;
    margin-bottom: 0.5em;
    margin-top: 40px;
}

.hof {
    width: 25%;
    margin-bottom: 0;
}

.bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.line {
    height: 5px; 
    width: 15vw; 
    background-color: #FF8888;
    border-radius: 5px; 
    box-shadow: 0px 8px 15px rgba(255, 107, 107, 0.5); 
}

.circle {
    height: 10px; 
    width: 10px; 
    background-color: #FF8888; 
    border-radius: 50%;
    margin: 0 10px; 
    box-shadow: 0px 8px 15px rgba(255, 107, 107, 0.5); 
}

.quote {
    font-size: 1.2rem; 
    margin-bottom: 2rem; 
}

.text {
    width: 100%;
    padding: 1rem 5%;
    font-size: 1.2rem; 
    /* background: url(../assets/HOF/Rectangle\ 507.png) no-repeat center center;  */
    background-size: cover; 
}

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

@media screen and (min-width: 550px) and (max-width: 850px) {
    .h {
        font-size: 1.3rem; 
        margin-top: 55px;
    }

    .hof {
        width: 35%; 
    }

    .quote {
        font-size: 1.2rem; 
    }

    .line {
        width: 15vw; 
    }
    .text {
        font-size: 1rem; 
        padding: 1rem; 
    }

    .circle {
        height: 8px; 
        width: 8px; 
    }

    .container {
        flex-direction: column; 
    }
}

@media screen and (max-width: 550px) {
    .outer {
        position: relative;
        top: 0;
    }

    .h {
        font-size: 1.5rem; 
        margin-top: 60px;   
    }

    .circle {
        height: 8px; 
        width: 8px; 
    }

    .hof {
        width: 65%; 
    }

    .quote {
        font-size: 1.1rem; 
        padding: 0 1rem; 
    }

    .bar {
        width: 90%; 
    }

    .line {
        width: 35vw; 
    }

    .text {
        font-size: 1rem; 
        padding: 1rem; 
    }

    .container {
        flex-direction: column; 
    }

    .grad {
        width: 100%;
    }
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
    .h {
        font-size: 1.5rem; 
        margin-top: 50px; 
    }

    .hof {
        width: 25%; 
    }

    .line {
        width: 15vw; 
    }

    .quote {
        font-size: 1rem; 
    }
}
