@import url("https://fonts.googleapis.com/css2?family=Freehand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Island+Moments&family=Karantina:wght@300;400;700&display=swap");

.container {
  width: 75vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  perspective: 1500px;
}

.heading {
  font-family: "Freehand", cursive;
  font-size: 3.5rem;
  color: white;
  text-shadow: 0px 4px 20px rgba(252, 155, 155, 1); 
}

.carousel {
  position: relative;
  height: 100%;
  column-gap: 4rem;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .container{
    width: 90vw;
  }
  .heading{
    font-size: 2.5rem;
  }
}