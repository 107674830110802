@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
html{
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Josefin Sans", sans-serif;
  color: white;
  /* background-color: #190019; */
  /* scrollbar-width: thin;
  scrollbar-color: var(--secondary-color) var(--tertiary-color); */
}

canvas{
  height: 100vh;
  width: 100vw;
}

:root {
  --primary-color: #0E1830;
  --secondary-color: #ff8888;
  --tertiary-color: #000000;
  --start-backgroundbg:#163272;

  --button-bg: #a41045;
  --loader-bg: #022962;
  --muted: #7d7e80;

  --color1: white;
  --color2: black;
  --color3: rgb(231, 231, 231);
  --color4: rgb(30, 30, 70);
  --color5: rgb(3, 133, 185);

  --backgroundQuery: #451952;
  --headingQuery: #efc36a;
  --headingMerch: #efc36a;
  --headingColorCard: #efc36a;
  --pMerch: white;
  --spanColorCard: white;
  --listMerch: white;
  --bgColorCard: #f8f9fa;
  --cardColor: #efc36a;

  --colorThreeContact: #7a316f;
  --colorFiveContact: #ebbd60;
  --contactWhite: #fff;

  --mainHeadingContactUs: #efc36a;
  --bgContactUs: #343a40;
  --teamHeading: whitesmoke;

  --firstColorSidebarContact: #ff8888;
  --secondColorSidebarContact: #ff8888;
  --thirdColorSidebarContact: #ff8888;
  --fourthColorSidebarContact: #ff8888;
  --fifthColorSidebarContact: #ff8888;

  --greyColorSidebarContact: white;

  --contactUsSvgFill: white;
  --nameColorContact: white;

  --schedule-color: rgb(241, 250, 238);

  --sponsor-color: rgba(255, 136, 136, 0.4);
  --bg-overlay-color: rgba(13, 12, 18, 0.7);

  --organizer-name: #22316C;

}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--primary-color);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 5px;
}