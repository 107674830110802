.cardContainer {
  width: 16.5rem;
  height: 16.5rem;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition-duration: 0.5s;
  cursor: pointer;
  border-radius: 1rem;
  box-sizing: border-box;
  overflow: visible;
  z-index: 999;
}

.profileDiv {
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(24, 24, 24);
  transition-duration: 0.5s;
  border-radius: 1rem;
  border: 2px solid #ff8888;
}

.cardContainer:hover .profileDiv {
  transform: translateX(-17%) translateY(-15%);
  transition-duration: 0.5s;
  overflow: visible;
}

.infoDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
  z-index: 1;
  border-radius: 1rem;
  padding: 0;
}

.nameDiv {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  background-color: aliceblue;
  font-family: Whitney, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  border-radius: 1rem;
}

.name {
  margin-bottom: 0.4rem;
  padding: 0;
  font-size: 1.3rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
}

.name-small {
  font-size: 1.2rem;
}

.socialDiv {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(14, 14, 14);
  gap: 1rem;
  right: 0;
}

.sociallink {
  color: rgba(0, 0, 0, 1);
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
}

.sociallink:hover {
  background: rgba(255, 255, 255, 0.2);
}

.organizerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.socialIcon {
  width: 1.8rem;
  height: 1.8rem;
}

/* Small Tablets and Mobile (width below 768px) */
@media (max-width: 768px) {
  .cardContainer {
    width: 16rem;
    height: 16rem;
  }
}

/* Extra Small Devices (width below 576px) */
@media (max-width: 576px) {
  .cardContainer {
    width: 16rem;
    height: 16rem;
    border-radius: 0.8rem;
  }

  .name {
    font-size: 1.25rem;
  }

  .name-small {
    font-size: 1.15rem;
  }
}

/* Very Small Devices (width below 400px) */
@media (max-width: 400px) {
  .cardContainer {
    width: 14rem;
    height: 14rem;
  }

  .profileDiv {
    border-radius: 0.5rem;
  }

  .name {
    font-size: 1.1rem;
  }

  .socialIcon {
    width: 1.4rem;
    height: 1.4rem;
  }

  .socialIconphone {
    width: 1rem;
    height: 1rem;
  }

  .sociallink {
    width: 1.2rem;
    height: 1.2rem;
  }
}