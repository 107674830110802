@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Mooli&display=swap");

.main {
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 15px;
  padding-top: 70px;
  padding-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Mooli', cursive;
  /* background: linear-gradient(#163272, #000000); */
  /* background-attachment: fixed; */
  box-sizing: border-box;
  /* background-image: linear-gradient(to bottom right,#2B124C,#854F6C); */
  /* background: linear-gradient(#151343, var(--tertiary-color)); */
  background: linear-gradient(#161444,#000000);
  background-attachment: fixed;
  background-size: cover;
}

.heading {
  display: block;
  width: 100%;
  margin: 1.5rem 0px;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Mooli', cursive;
  display: block;
  text-align: center;
  color: var(--color1);
  font-family: 'Freehand', sans-serif;
  font-size: 5rem;
  z-index: 2;
  text-shadow: 0px 4px 20px rgba(252, 155, 155, 1);
}

@media (max-width: 768px) {
  .heading {
    display: block;
    font-size: 3em;
  }
}