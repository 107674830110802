@import url('https://fonts.googleapis.com/css2?family=Brygada+1918&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.formcontainer {
    width: 450px;
    /* height: 70vh; */
    /* From https://css.glass */
    /* background: rgba(81, 60, 81, 0.71);
     border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.2px);
    -webkit-backdrop-filter: blur(8.2px); */
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border: 3px solid var(--secondary-color);
    /* margin: 40px 100px 100px 0px; */
    padding: 40px 24px 24px 24px;
    font-size: 18px;
    font-family: 'Brygada 1918', serif;
    font-family: "Josefin Sans", sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.formcontainer button:active {
    scale: 0.95;
}

.maintitle {
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    text-transform: uppercase;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formcontainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.formgroup {
    width: 100%;
    margin: 15px 0px 15px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.formcontainer .formgroup input::placeholder {
    opacity: 0.6;
}

.formcontainer .formgroup input:focus {
    outline: none;
    border-color: #1778f2;
}

.formbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: inherit;
    color: #fff;
    background-color: #212121;
    color: var(--secondary-color);
    border: none;
    width: 40%;
    padding: 12px 16px;
    font-size: inherit;
    gap: 8px;
    margin: 12px 0;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.formcontainer .formbtn:hover {
    background-color: #313131;
    margin: 25px;
}

.formcontainer .link {
    color: #1778f2;
    text-decoration: none;
}

.form-container .link:hover {
    text-decoration: underline;
}

.labeltext {
    width: 30%;
    margin-right: 15px;
    padding-top: 10px;
}

.title {
    border: none;
    outline: none;
    background: transparent;
    color: var(--secondary-color);
    border-bottom: 1px solid var(--secondary-color);
}

.title::placeholder {
    color: var(--secondary-color);
    opacity: 0.5;
}

.title:focus {
    outline: none;
    border-color: #1778f2;
}

.title select{
    color: black;
    background: black;
}

@media only screen and (max-width: 768px) {
    .formcontainer {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .formbtn {
        width: 70%;
    }

    .formgroup {
        padding: 0 25px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .labeltext {
        width: 100%;
        margin-right: 0;
    }

    .title {
        width: 100%;
        padding: 5px;
    }
    .maintitle{
        font-size: 20px;
        padding: 25px;
    }
}